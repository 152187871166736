<template>
  <div class="DevelopmentSetting bg-white p-20">
    <a-spin :spinning="loading">
      <div style="font-size: 20px;">
        <div class="dispaly">
          <div style="width:120px">keyId：</div>
          <el-input v-model="info.keyId" :readonly="true" :type="password">
            <i slot="suffix" class="el-icon-view icons " @click="showPass1"></i>
            <div slot="append" class="copys" @click="copy(info.keyId)">复制</div>
          </el-input>
        </div>
        <div class="dispaly" style="margin:15px 0">
          <div style="width:120px">keySecret：</div>
          <el-input v-model="info.keySecret" :readonly="true" :type="password1">
            <i slot="suffix" class="el-icon-view icons" @click="showPass2"></i>
            <div slot="append" class="copys" @click="copy(info.keySecret)">复制</div>
          </el-input>
        </div>
        <div>回调地址：
          <div style="margin-top:5px">
            <el-input v-model="info.refundUrl" :disabled="readonly" @input="chufa">
              <div slot="append" class="dispaly">
                <span v-if="readonly" class="el-icon-edit-outline" style="color: #409eff;cursor: pointer; font-size: 20px;margin-right: 5px;margin-top:3px"
                  @click="readonly = false"></span>
                <label v-if="readonly" @click="readonly = false">编辑</label>
                <label v-if="!readonly" @click="handleEdit">保存</label>
                <label v-if="!readonly" class="cancal" @click="readonly = true">取消</label>
              </div>
            </el-input>

          </div>
        </div>
        <div class="chakna API" style="margin-top: 15px;">
          <a @click="API($tp().OPEN_API_DOC)">查看API对接文档</a>
        </div>
      </div>
      <!-- http://v5a.aijdan.com/mall/supply/supply-notify/1569519508152205314 -->
    </a-spin>
  </div>
</template>

<script>
import { editRefundUrl } from '@/api/development'
import { getUserInfoRz } from '@/api/Login'

/**
 * @author  XuHongli
 * @date  2022/9/16 19:40
 * @version 1.0
 * @description
 */
export default {
  name: 'DevelopmentSetting',
  data() {
    return {
      cacheEdit: '',
      editable: false,
      loading: false,
      info: {
        keyId: '',
        keySecret: '',
        refundUrl: ''
      },
      readonly: true,
      password: 'password',
      password1: 'password',
      chufas: false,
    }
  },
  created() {
    this.loading = true
    getUserInfoRz().then((res) => {
      this.info.keyId = res.data.keyId
      this.info.keySecret = res.data.keySecret
      this.info.refundUrl = res.data.refundUrl
      this.loading = false
    })
  },
  methods: {
    API(url) {
      window.open(url, '_blank')
    },
    // 复制
    copy(value) {
      // 创建输入框元素
      let oInput = document.createElement('input');
      // 将想要复制的值
      oInput.value = value;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand('Copy');
      // 弹出复制成功信息
      this.$message.success('复制成功');
      // 复制后移除输入框
      oInput.remove();
      console.log('copy', value)
    },
    // 显示隐藏
    showPass1() {
      console.log('dsfsfs')
      if (this.password == "text") {
        this.password = "password";
        //更换图标
        this.icon = "el-input__icon el-icon-view";
      } else {
        this.password = "text";
        this.icon = "el-input__icon el-icon-loading";
      };
    },
    showPass2() {
      console.log('dsfsfs', this.password1)
      if (this.password1 == "text") {
        this.password1 = "password";
        //更换图标
        this.icon = "el-input__icon el-icon-view";
      } else {
        this.password1 = "text";
        this.icon = "el-input__icon el-icon-loading";
      };
    },
    showPass() { this.readonly2 = !this.readonly2 },

    chufa() {
      console.log('触发')
      this.chufas = true
    },
    handleEdit() {

      editRefundUrl(this.info.refundUrl).then((res) => {
        this.$notification.success({
          message: `修改回调地址成功`,
          description:
            `您已经成功修改回调地址, 配置预计1-15分钟后生效`,
        })
        this.info.refundUrl = this.info.refundUrl
        this.readonly = true
      }).catch(() => {
        // this.$notification.error({
        //   message: `修改回调地址失败`,
        //   description:
        //     `系统错误请联系管理员`,
        // })
        this.info.refundUrl = this.cacheEdit
      })
    }
  },
}
</script>

<style scoped>
.DevelopmentSetting {
  background-color: #fff;
}

.d-text {
  font-size: 22px;
  font-weight: 700;
  color: #141414;
}

.API a {
  color: #458ae4;
  font-size: 19px;

}

.icons {
  margin-top: 10px;
  margin-right: 5px;
  font-size: 20px
}

.dispaly {
  display: flex;
  align-items: center;
}

.chakna {
  text-align: right;
}

.copys {
  cursor: pointer;
}

.cancal {
  margin-left: 10px;
  position: relative;
  padding-left: 10px;
  cursor: pointer;
}

.cancal::before {
  content: '';
  position: absolute;
  width: 1px;
  height: 20px;
  top: 0px;
  left: 0;
  background: #b3aaaa;
}
</style>
