<template>
  <div class="userCenter bg-white p-10">
    <DevelopmentSetting></DevelopmentSetting>
  </div>
</template>

<script>/**
 * @author  XuHongli
 * @date  2022/8/29 9:35
 * @version 1.0
 * @description 用户中心
 */
import { userNavOptions } from '@/views/User/userNavOptions'
import DefaultNavigation from '@/components/defaultNavigation'
import Shortcuts from '@/components/Shortcuts'
import UserCenterMessage from '@/views/User/userCenter/userCenterMessage/userCenterMessage'
import { mapGetters } from 'vuex'
import DevelopmentSetting from '@/views/User/development/DevelopmentSetting'
export default {
  name: 'Development',
  components: { DevelopmentSetting},
  data() {
    return {
      userNavOptions,
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
}
</script>
<style scoped lang="scss">
.heart{
  font-size: 22px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.top {
  font-weight: bold;
  font-size: 22px;
  text-align: left;
  color: #202124;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  p{
    font-size: 20px;
  }
  .p {
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    color: #707070;
    cursor: pointer;
  }
}

</style>

<style scoped lang="scss">
.userCenter {
  background-color: rgb(255,255,255) !important;
  min-height: 100%;
}
.content {
  display: flex;
  .right-content{
    margin-left: 30px;
    width: 100%;
    background-color: #fff;
    justify-content: flex-end;
  }
}

</style>
