/**
 * @author  XuHongli
 * @date  2022/9/16 19:45
 * @version 1.0
 * @description
 */
import request from '@/utils/axios'

/**
 * 编辑回调地址
 * @param refundUrl {string}
 * @return {*}
 */
export function editRefundUrl(refundUrl) {
  return request({
    url: '/supplyportal/purchaserinfo/editRefundUrl',
    method: 'get',
    params: { refundUrl }
  })
}
