<template>
  <div class="top-left-bottom top-left-top" style="height: 120px; margin-bottom: 0;">
    <div v-for="(item, index) in thePagers.pagesInfo" :key="item.id">
      <ul v-if="thePagers.currentPage === index + 1" class="select-ul">
        <li>{{ item.times }}</li>
        <li class="overflow" :title="item.messages"><a href="">{{ item.messages }}</a></li>
      </ul>
      <div class="left-bottom-footer" v-if="thePagers.currentPage === index + 1">
        <text>{{ thePagers.currentPage }}/{{ thePagers.pages }}</text>
        <div class="left-right-btn">
          <span @click="thePagers.currentPage = 1"> &lt; </span>
          <span @click="thePagers.currentPage = 2"> > </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/8/29 15:03
 * @version 1.0
 * @description
 */
export default {
  name: 'UserCenterMessage',
  data() {
    return {
      thePagers: {
        pages: 2,
        currentPage: 1,
        pagesInfo: [
          {
            id: 1,
            times: '2022-03-22',
            messages: '硬币到期通知！',
          },
          {
            id: 2,
            times: '2022-03-04',
            messages: '邀请好友赢取高达 $25 的礼券奖励',
          },
        ],
      },
    }
  },
}
</script>

<style scoped lang="scss">
.top-left-bottom {
  width: 191px;
  padding: 14px 10px 13px 20px;
  background-color: #fff;
  position: relative;

  .left-bottom-footer {
    display: flex;
    justify-content: right;

    .left-right-btn {
      border: 1px solid #ccc;
      margin-left: 10px;
      position: absolute;
      bottom: 10px;
      span {
        display: inline-block;
        text-align: center;
        width: 21px;
        height: 21px;
        cursor: pointer;

        &:nth-child(1) {
          border-right: 1px solid #ccc;
        }
      }
    }
  }
}
.select-ul {
  text-indent: 1rem;
  padding-left: 0;

  li {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    a:hover {
      color: #ff8600 !important;
      text-decoration: underline;
    }
  }
}
</style>
